import React from "react"
// import { AnimationOnScroll } from "react-animation-on-scroll"
// import Partner from "../images/MEPSC.png"
// import Partner1 from "../images/logo.png"
// import Partner2 from "../images/_layout/logo/ilss.jpg"
// import Partner3 from "../images/_layout/logo/2/asdc.png"
// import Partner4 from "../images/_layout/logo/3/TSSC.png"
// import Partner5 from "../images/_layout/logo/3/RASCI.jpg"
// import Partner6 from "../images/_layout/logo/3/NSDC.jpg"
// import Partner7 from "../images/_layout/logo/3/NIITYJ.png"
// import Partner8 from "../images/_layout/logo/2/HVTI.png"
// import Partner9 from "../images/_layout/logo/2/final.jpg"
// import Partner10 from "../images/_layout/logo/2/centum.jpg"
// import Partner11 from "../images/_layout/logo/2/asdc.png"
// import Partner12 from "../images/_layout/logo/1/SSSDC.jpg"
// import Partner13 from "../images/_layout/logo/1/iitd_logo.png"
// import Partner14 from "../images/_layout/logo/1/honda-motorcycle-logo.png"
// import Partner15 from "../images/_layout/logo/1/G4S.png"
// import Partner16 from "../images/_layout/logo/the-skills-academy-logo.png"
// import Partner17 from "../images/_layout/logo/rooman.jpg"
// import Partner18 from "../images/_layout/logo/logo_laurus.png"
// import Partner19 from "../images/_layout/logo/ilfs1.png"
// import Partner20 from "../images/_layout/logo/nifa.jpg"
// import Partner21 from "../images/_layout/logo/future_logo.jpg"
// import Partner22 from "../images/_layout/logo/gram.jpg"
// import Partner24 from "../images/_layout/logo/egrowth_transparent.png"
// import Partner25 from "../images/_layout/logo/GreenJobsLogo.png"
// import Partner26 from "../images/_layout/logo/JubilantBhartiaFoundation.png"
// import Partner27 from "../images/_layout/logo/sp-logo.png"
// import Partner28 from "../images/_layout/logo/MSDELogoEng-01.png"
// import Partner29 from "../images/_layout/logo/UNDP_Logo-Blue w Tagline-ENG-1.png"
// import Partner30 from "../images/_layout/logo/Hero_MotoCorp_Logo.png"
// import Partner31 from "../images/_layout/logo/NREDCAP LOGO.png"
// import Partner32 from "../images/_layout/logo/essi.jpg"
// import Partner33 from "../images/_layout/logo/PepsiCo-Logo.png"
// import Partner34 from "../images/_layout/logo/schoolnet.png"
// import Partner35 from "../images/_layout/logo/download.jpeg"
// import Partner36 from "../images/_layout/logo/collins_logo.png"
// import Partner37 from "../images/_layout/logo/SIA-India-New-V1.jpg"
// import Partner38 from "../images/_layout/logo/ASCI.png"
// import Partner39 from "../images/_layout/logo/BIPPLOGO.jpg"
// import Partner40 from "../images/_layout/logo/cscAcademy.jpeg"
// import Partner42 from "../images/_layout/logo/MNRE_Logo.png"
// import Partner41 from "../images/_layout/logo/unitedwayDElhi.jpg"
// import SattvaLogo from "../images/_layout/logo/SattvaLogo.png"
// import mirraStore from "../images/_layout/logo/Mirra-logo.png"
// import karrepLogo from "../images/_layout/logo/karrep_logo.jpg"

export default function partners() {
  //   const card_heading_inviting_conclave = [
  //     {
  //       heading: "Government",
  //       text: "Create an ESG-supporting ecosystem to raise ESG awareness in the state and promote a green and sustainable economy.",
  //     },
  //     {
  //       heading: "Corporates",
  //       text: "Learn and share Environmental, Social, and Governance best practices from around the World and India.",
  //       anotherText: "Value creation through a strong ESG proposition.",
  //     },
  //     {
  //       heading: "Social Organizations",
  //       text: "Contribute to building India's ESG ecosystem through fostering relationships with Corporates, Government and Communities.",
  //     },
  //     {
  //       heading: "ESG Enablers",
  //       text: "Enhance your ESG service offerings for Corporates, MNCs, CSRs, ESG Departments and Governments.",
  //     },
  //   ]
  //   const partnersLogo = [
  //     {
  //       url: Partner28,
  //     },
  //     {
  //       url: Partner24,
  //     },
  //     {
  //       url: Partner25,
  //     },
  //     {
  //       url: Partner29,
  //     },
  //     {
  //       url: Partner27,
  //     },
  //     {
  //       url: Partner4,
  //     },
  //     {
  //       url: Partner30,
  //     },
  //     {
  //       url: Partner31,
  //     },
  //     {
  //       url: Partner33,
  //     },
  //     {
  //       url: Partner34,
  //     },
  //     {
  //       url: Partner5,
  //     },
  //     {
  //       url: Partner6,
  //     },
  //     {
  //       url: Partner35,
  //     },
  //     {
  //       url: Partner7,
  //     },
  //     {
  //       url: Partner8,
  //     },
  //     {
  //       url: Partner9,
  //     },
  //     {
  //       url: Partner10,
  //     },
  //     {
  //       url: Partner11,
  //     },
  //     {
  //       url: Partner12,
  //     },
  //     {
  //       url: Partner13,
  //     },
  //     {
  //       url: Partner14,
  //     },
  //     {
  //       url: Partner15,
  //     },
  //     {
  //       url: Partner16,
  //     },
  //     {
  //       url: Partner17,
  //     },
  //     {
  //       url: Partner18,
  //     },
  //     {
  //       url: Partner19,
  //     },
  //     {
  //       url: Partner20,
  //     },
  //     {
  //       url: Partner21,
  //     },
  //     {
  //       url: Partner22,
  //     },
  //     {
  //       url: Partner36,
  //     },
  //     {
  //       url: karrepLogo,
  //     },
  //   ]
  return (
    <>
      <div className="">Partners</div>
    </>
  )
  //       <div className=" text-center mt-5 pt-5 mb-5 px-5">
  //         <h1 className="heading mb-5">INVITING CONCLAVE PARTNERS AS</h1>
  //         <div className="row" style={{ fontSize: "18px" }}>
  //           {card_heading_inviting_conclave.map(data => {
  //             return (
  //               <div className="col-12 col-md-6 col-lg-3 mt-3 mt-md-3 mt-lg-0">
  //                 <div
  //                   className="card shadow-lg p-3 custom-card fw-bold"
  //                   // style={{ background: "rgb(123, 130, 78)" }}
  //                 >
  //                   <h3
  //                     className="card-title card-heading text-center"
  //                     style={{ fontWeight: "bold" }}
  //                   >
  //                     {data.heading}
  //                   </h3>
  //                   <div className="card-body text-center">
  //                     {data.text}
  //                     <div className="pt-3">{data.anotherText}</div>
  //                   </div>
  //                 </div>
  //               </div>
  //             )
  //           })}
  //         </div>
  //         <div className="row">
  //           <div className="col-12 col-md-6 col-lg-4 mt-5 border p-5">
  //             <span className="partners">Principal Partner:</span>
  //             <br />
  //             <br />
  //             <img
  //               src={Partner}
  //               style={{ width: "42%" }}
  //               alt={``}
  //               className="img-fluid"
  //             />
  //           </div>
  //           <div className="col-12 col-md-6 col-lg-4 mt-5 border p-5">
  //             <span className="partners">Implementing Partner:</span>
  //             <br />
  //             <br />
  //             <img src={Partner1} className="img-fluid" alt={``} />
  //           </div>
  //           <div className="col-12 col-md-6 col-lg-4 mt-5 border p-5">
  //             <span className="partners">Supported By:</span>
  //             <br />
  //             <br />
  //             <img src={Partner42} className="img-fluid" alt={``} />
  //           </div>
  //           {/* <div className="col-12 col-md-6 col-lg-4 mt-5 border p-5">
  //             <span className="partners">Media Partner:</span>
  //             <br />
  //             <br />
  //             <img
  //               src={FinalAKBLogo}
  //               style={{ width: "27%" }}
  //               className="img-fluid"
  //               alt={``}
  //             />
  //           </div> */}
  //           <div className="col-12 col-md-6 col-lg-4 mt-5 border p-5">
  //             <span className="partners">Knowledge Partner:</span>
  //             <br />
  //             <br />
  //             <img
  //               src={Partner39}
  //               style={{ width: "65%" }}
  //               className="img-fluid"
  //               alt={``}
  //             />
  //           </div>
  //           <div className="col-12 col-md-6 col-lg-4 mt-5 border p-5">
  //             <span className="partners">Association Partner:</span>
  //             <br />
  //             <br />
  //             <img
  //               src={Partner37}
  //               style={{ width: "41%" }}
  //               className="img-fluid"
  //               alt={``}
  //             />
  //           </div>
  //           <div className="col-12 col-md-6 col-lg-4 mt-5 border p-5">
  //             <span className="partners">Strategic Partner:</span>
  //             <br />
  //             <br />
  //             <img
  //               src={SattvaLogo}
  //               style={{ width: "35%" }}
  //               className="img-fluid"
  //               alt={``}
  //             />
  //           </div>
  //           <div className="col-12 col-md-6 col-lg-4 mt-5 border p-5">
  //             <span className="partners">Sectoral/ Skilling Partners:</span>
  //             <br />
  //             <br />
  //             <img
  //               className="img-fluid"
  //               src={Partner3}
  //               style={{
  //                 width: "22%",
  //                 paddingRight: "12px",
  //               }}
  //               alt={``}
  //             />
  //             <img
  //               alt={``}
  //               src={Partner32}
  //               style={{
  //                 width: "22%",
  //                 paddingLeft: "12px",
  //                 borderLeft: "3px solid #e7e7e7",
  //               }}
  //             />
  //             <img
  //               alt={``}
  //               src={Partner38}
  //               style={{
  //                 width: "22%",
  //                 borderLeft: "3px solid #e7e7e7",
  //                 paddingRight: "12px",
  //               }}
  //             />
  //             <img
  //               alt={``}
  //               src={Partner40}
  //               style={{
  //                 width: "22%",
  //                 borderLeft: "3px solid #e7e7e7",
  //                 paddingLeft: "12px",
  //               }}
  //             />
  //           </div>
  //           <div className="col-12 col-md-6 col-lg-4 mt-5 border p-5">
  //             <span className="partners">NGO Partners:</span>
  //             <br />
  //             <br />
  //             <img
  //               alt={``}
  //               src={Partner41}
  //               style={{
  //                 width: "28%",
  //                 borderRight: "3px solid #e7e7e7",
  //                 paddingRight: "12px",
  //               }}
  //             />
  //             <img
  //               alt={``}
  //               src={Partner2}
  //               style={{
  //                 width: "25%",
  //                 paddingLeft: "12px",
  //                 paddingRight: "12px",
  //               }}
  //             />
  //             <img
  //               alt={``}
  //               src={Partner26}
  //               style={{
  //                 width: "18%",
  //                 paddingLeft: "12px",
  //                 borderLeft: "3px solid #e7e7e7",
  //               }}
  //             />
  //           </div>
  //           <div className="col-12 col-md-6 col-lg-4 mt-5 border p-5">
  //             <span className="partners">Gifting Partner:</span>
  //             <br />
  //             <br />
  //             <img
  //               alt={``}
  //               src={mirraStore}
  //               style={{ width: "35%" }}
  //               className="img-fluid"
  //             />
  //           </div>
  //         </div>
  //         {/* <div className="row pt-5 text-center">
  //           <div className="col-12 col-md-6 col-lg-4 mt-5"></div>
  //           <div className="col-12 col-md-6 col-lg-4 mt-5">
  //             <span className="partners">Implementing Partner:</span>
  //             <br />
  //             <br />
  //             <img src={Partner1} style={{ width: "40%" }} />
  //           </div>
  //           <div className="col-12 col-md-6 col-lg-4 mt-5 mt-lg-0">
  //             <span className="partners">Supported By:</span>
  //             <br />
  //             <br />
  //             <img
  //               src={Partner42}
  //               style={{
  //                 width: "70%",
  //               }}
  //             />
  //           </div>
  //           <div className="col-12 col-md-6 col-lg-4 mt-5 mt-lg-0">
  //             <span className="partners">Media Partner:</span>
  //             <br />
  //             <br />
  //             <img
  //               src={FinalAKBLogo}
  //               style={{
  //                 width: "35%",
  //               }}
  //             />
  //           </div>
  //           <div className="col-12 col-md-6 col-lg-4 mt-5">
  //             <span className="partners">Knowledge Partner:</span>
  //             <br />
  //             <br />
  //             <img
  //               src={Partner39}
  //               style={{
  //                 width: "60%",
  //               }}
  //             />
  //           </div>
  //           <div className="col-12 col-md-6 col-lg-4 mt-5">
  //             <span className="partners">Association Partner:</span>
  //             <br />
  //             <br />
  //             <img
  //               src={Partner37}
  //               style={{
  //                 width: "40%",
  //               }}
  //             />
  //           </div>
  //           <div className="col-12 col-md-6 col-lg-4 mt-5">
  //             <span className="partners">Strategic Partner:</span>
  //             <br />
  //             <img
  //               src={SattvaLogo}
  //               style={{
  //                 width: "40%",
  //               }}
  //             />
  //           </div>
  //           <div className="col-12 col-md-6 col-lg-4 mt-5">
  //             <span className="partners">Sectoral/ Skilling Partners:</span>
  //             <br />
  //             <br />
  //             <img
  //               src={Partner3}
  //               style={{
  //                 width: "20%",
  //                 paddingRight: "12px",
  //               }}
  //             />
  //             <img
  //               src={Partner32}
  //               style={{
  //                 width: "20%",
  //                 paddingLeft: "12px",
  //                 borderLeft: "3px solid #e7e7e7",
  //               }}
  //             />
  //             <img
  //               src={Partner38}
  //               style={{
  //                 width: "20%",
  //                 borderLeft: "3px solid #e7e7e7",
  //                 paddingRight: "12px",
  //               }}
  //             />
  //             <img
  //               src={Partner40}
  //               style={{
  //                 width: "20%",
  //                 borderLeft: "3px solid #e7e7e7",
  //                 paddingLeft: "12px",
  //               }}
  //             />
  //           </div>
  //           <div className="col-12 col-md-6 col-lg-4 mt-5">
  //             <span className="partners">NGO Partners:</span>
  //             <br />
  //             <br />
  //             <img
  //               src={Partner41}
  //               style={{
  //                 width: "28%",
  //                 borderRight: "3px solid #e7e7e7",
  //                 paddingRight: "12px",
  //               }}
  //             />
  //             <img
  //               src={Partner2}
  //               style={{
  //                 width: "25%",
  //                 paddingLeft: "12px",
  //                 paddingRight: "12px",
  //               }}
  //             />
  //             <img
  //               src={Partner26}
  //               style={{
  //                 width: "18%",
  //                 paddingLeft: "12px",
  //                 borderLeft: "3px solid #e7e7e7",
  //               }}
  //             />
  //           </div>
  //           <div className="col-12 col-md-6 col-lg-4 mt-5">
  //             <span className="partners">Gifting Partner:</span>
  //             <br />
  //             <img
  //               src={mirraStore}
  //               style={{
  //                 width: "30%",
  //               }}
  //             />
  //           </div>
  //         </div> */}
  //         <div className="overflow-hidden">
  //           <div className="page-main page-brands">
  //             <div className="pt-5 mt-5">
  //               <h4 className="partners" style={{ textAlign: "center" }}>
  //                 Our Work Partners:
  //               </h4>
  //             </div>
  //             <ul className="brands_list">
  //               {partnersLogo.map(data => {
  //                 return (
  //                   <li className="brand_item">
  //                     <h2 className="brand_logo">
  //                       <a title="" href="">
  //                         <img className="brand_logo" src={data.url} alt={``} />
  //                       </a>
  //                     </h2>
  //                   </li>
  //                 )
  //               })}
  //             </ul>
  //           </div>
  //         </div>
  //       </div>
}
